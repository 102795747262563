export default {
  palette: {
    text: {
      primary: '#606060',
      secondary: '#999999'
    },
    primary: {
      main: '#143cdb',
      contrastText: '#EBEBEB'
    },
    secondary: {
      main: '#606060',
      contrastText: '#EBEBEB'
    }
  },
  // Enable typography v2: https://material-ui.com/style/typography/#migration-to-typography-v2
  typography: {
    fontFamily: ['Helvetica Neue', 'Helvetica', 'Arial'].join(','),
    useNextVariants: true
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  }
}
