import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore' // make sure you add this for firestore
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import { Provider } from 'react-redux'
import ThemeSettings from 'theme'
import { firebase as fbConfig, reduxFirebase as rfConfig } from 'config'

let theme = createMuiTheme(ThemeSettings)
theme = responsiveFontSizes(theme)

// Initialize Firebase instance
firebase.initializeApp(fbConfig)
// Initialize Cloud Firestore through Firebase
firebase.firestore()

// Initialize Firebase Analytics
firebase.analytics()

function App({ routes, store }) {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <ReactReduxFirebaseProvider
          firebase={firebase}
          config={rfConfig}
          dispatch={store.dispatch}
          createFirestoreInstance={createFirestoreInstance}>
          <Router>{routes}</Router>
        </ReactReduxFirebaseProvider>
      </Provider>
    </MuiThemeProvider>
  )
}

App.propTypes = {
  routes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
}

export default App
