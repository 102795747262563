/**
 * NOTE: This file is ignored from git tracking. In a CI environment it is
 * generated by firebase-ci based on config in .firebaserc (see .gitlab-ci.yaml).
 * This is done so that environment specific settings can be applied.
 */

const env = 'local'

// Config for firebase
const firebase = {
  apiKey: "AIzaSyB4z357eNIhS0AJ0jz9Eef2QHSF5M-bECw",
  authDomain: "philmod-godds.firebaseapp.com",
  databaseURL: "https://philmod-godds.firebaseio.com",
  projectId: "philmod-godds",
  storageBucket: "philmod-godds.appspot.com",
  messagingSenderId: "694633187983",
  appId: "1:694633187983:web:088c03134220ab2f",
  measurementId: "G-5G9EC86Y75",
};

// Config to override default reduxFirebase config in store/createStore
// which is not environment specific.
// For more details, visit http://react-redux-firebase.com/docs/api/enhancer.html
const reduxFirebase = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableLogging: false, // enable/disable Firebase Database Logging
}

module.exports = {
  env,
  firebase,
  reduxFirebase,
}
