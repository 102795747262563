import { Switch, Route, Redirect } from 'react-router-dom'
import AccountRoute from './Account'
import AdminRoute from './Admin'
import ContestsRoute from './Contests'
import CoreLayout from '../layouts/CoreLayout'
import Home from './Home'
import LoginRoute from './Login'
import NotFoundRoute from './NotFound'
import React from 'react'
import SignupRoute from './Signup'

export default function createRoutes(store) {
  return (
    <CoreLayout>
      <Switch>
        <Route exact path={Home.path} component={() => <Redirect to="/contests" />} />
        {
          /* Build Route components from routeSettings */
          [
            AccountRoute,
            AdminRoute,
            ContestsRoute,
            LoginRoute,
            SignupRoute
            /* Add More Routes Here */
          ].map((settings, index) => (
            <Route key={`Route-${index}`} {...settings} />
          ))
        }
        <Route component={NotFoundRoute.component} />
      </Switch>
    </CoreLayout>
  )
}
