/**
 * Log a message and return data passed. Useful for logging
 * messages within functional programming flows.
 * @param message - Message to log along with data.
 * @example Basic
 * import { flow, map as fpMap } from 'lodash'
 * const original = []
 * flow(
 *   fpLog('Before Map'),
 *   fpMap('name')
 *   fpLog('After Map'),
 * )(original)
 * // => 'Before Map' [{ name: 'test' }]
 * // => 'After Map' ['test']
 */
export function fpLog(message) {
  return (existing) => {
    console.log(message, existing) // eslint-disable-line no-console
    return existing
  }
}

/**
 * Initialize global scripts including analytics and error handling
 */
export function initScripts() {
  // Initialize global scripts here
}

export function dict2array(dict) {
  if (!dict) {
    return []
  }
  let arr = []
  for (var key in dict) {
    if (dict.hasOwnProperty(key)) {
      let item = Object.assign({}, dict[key])
      item.id = key
      arr.push(item)
    }
  }
  return arr
}

export function roundToHour(date) {
  const p = 60 * 60 * 1000 // milliseconds in an hour
  return new Date(Math.round(date.getTime() / p) * p)
}

export function diffDatesHours(a, b) {
  const diffInMilliseconds = Math.abs(a - b)
  const hours = Math.floor(diffInMilliseconds / 3600)
  return hours
}

export function addHours(date, hours) {
  var twoHoursBefore = new Date(date)
  twoHoursBefore.setHours(twoHoursBefore.getHours() + hours)
  return twoHoursBefore
}
