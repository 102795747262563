import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import { ACCOUNT_PATH, ADMIN_PATH } from 'constants/paths'
import { useNotifications } from 'modules/notification'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    color: 'white'
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  }
}))

function AccountMenu() {
  const classes = useStyles()
  const [anchorEl, setMenu] = useState(null)
  const history = useHistory()
  const firebase = useFirebase()
  const { showError } = useNotifications()

  const profile = useSelector((state) => state.firebase.profile)
  const isAdmin = profile.admin === true

  function closeAccountMenu(e) {
    setMenu(null)
  }
  function handleMenu(e) {
    setMenu(e.target)
  }
  function handleLogout() {
    return firebase
      .logout()
      .then(() => {
        closeAccountMenu()
        history.push('/')
      })
      .catch((err) => showError(err && err.message))
  }
  function goToAccount() {
    closeAccountMenu()
    history.push(ACCOUNT_PATH)
  }
  function goToAdmin() {
    closeAccountMenu()
    history.push(ADMIN_PATH)
  }
  function avatar() {
    if (profile && profile.avatarUrl) {
      return <Avatar src={profile.avatarUrl} className={classes.small} />
    }
    return <AccountCircle />
  }

  return (
    <Fragment>
      <IconButton
        aria-owns={anchorEl ? 'menu-appbar' : null}
        aria-haspopup="true"
        onClick={handleMenu}
        classes={{ root: classes.buttonRoot }}>
        {avatar()}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={closeAccountMenu}>
        <MenuItem onClick={goToAccount}>Account</MenuItem>
        {isAdmin && <MenuItem onClick={goToAdmin}>Admin</MenuItem>}
        <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
      </Menu>
    </Fragment>
  )
}

AccountMenu.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired // from enhancer (withRouter)
  }),
  firebase: PropTypes.shape({
    logout: PropTypes.func.isRequired // from enhancer (withFirebase)
  })
}

export default AccountMenu
