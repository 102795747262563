import React from 'react'
import styles from './HomePage.styles'
import Typography from '@material-ui/core/Typography'
import { CONTESTS_PATH } from 'constants/paths'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(styles)

function Home() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className="flex-row-center">
        <Typography>godds is a website to bet on soccer tournaments between friends.</Typography>
        <Typography>
          Go to the <Link to={CONTESTS_PATH}>contests page</Link> to get started.
        </Typography>
      </div>
    </div>
  )
}

export default Home
