import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import StackdriverErrorReporter from 'stackdriver-errors-js'
import { initScripts } from './utils'
import createStore from './store/createStore'
import { version } from '../package.json'
import { env } from './config'
import App from './containers/App'
import './index.css'

// import * as serviceWorker from './serviceWorker'

// Window Variables
// ------------------------------------
window.version = version
window.env = env
initScripts()

// Stackdriver error reporting
// ------------------------------------
window.errorHandler = new StackdriverErrorReporter()
window.errorHandler.start({
  key: 'AIzaSyBhqvrS32LJoEFI2yM0ut4W6LXATYl_Xzc',
  projectId: 'philmod-godds'
})

// Google Analytics
// ------------------------------------
ReactGA.initialize('208006869')
ReactGA.pageview(window.location.pathname + window.location.search)

// Store Initialization
// ------------------------------------
const initialState = window.___INITIAL_STATE__ || {
  firebase: { authError: null }
}
const store = createStore(initialState)
const routes = require('./routes/index').default(store)

ReactDOM.render(<App store={store} routes={routes} />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
